import AbstractEditable from './AbstractEditable';
import BscMgr from '../../../../gui/BscMgr';
import Validator from '../../../../utils/Validator';
import XtwBody from '../../XtwBody';
import XCellItem from '../../parts/XCellItem';
import EditTarget from './EditTarget';
import XtwCol from '../../parts/XtwCol';
import HtmHelper from '../../../../utils/HtmHelper';
import XRowItem from '../../parts/XRowItem';
import EditRequest from './EditRequest';
import CellCtt from '../../model/CellCtt';

const INPUT_NOTIFICATION = "textInput";
const INPUT_EDITING_EVENT = "edit";
const INPUT_SAVE_EVENT = "save";
const INPUT_FULL_SAVE_EVENT = "fullSave";
const INPUT_CANCEL_EVENT = "cancel";
const INPUT_MODIFIED_EVENT = "modified";

export default class EditableElement extends AbstractEditable {

	/**
	 * constructs a new instance
	 * @param {XCellItem} cellObject the target cell
	 * @param {String} ln logger name
	 */
	constructor( cellObject, ln ) {
		super(cellObject.idc, cellObject.idr, ln || 'widgets.xtw.editing.EditableElement');
		this._cell = cellObject;
		this._row = cellObject.row;
		this._column = cellObject.column;
		this._dirty = false;
		this._editingAllowed = true;
		this._ovrOrgValue = null;
		const ids = `xtw-editable-${this.getType()}-${this.iid}`;
		Object.defineProperties( this, {
			_instanceID: {
				value: ids,
				writable: false,
				configurable: false
			}
		});
		this._target = null;
		this._input = null;
	}

	/**
	 * @override
	 */
	doDestroy() {
		this.destroySelf();
		super.doDestroy();
	}

	/**
	 * @returns {String} the instance ID
	 */
	get instanceID() {
		return this._instanceID;
	}

	/**
	 * @returns {XCellItem} the target cell
	 */
	get cell() {
		return this._cell;
	}

	/**
	 * @returns {XtwCol} the column
	 */
	get column() {
		return this._column;
	}

	/**
	 * @returns {XRowItem | null} the target row
	 */
	get row() {
		return this._row;
	}

	/**
	 * @returns {Number} the column ID
	 */
	get idc() {
		return this.columnId;
	}

	/**
	 * @returns {Number} the row ID of the target row
	 */
	get idr() {
		return this.rowId;
	}
	
	/**
	 * @returns {String} the ID of the input element
	 */
	get inputId() {
		return this.instanceID;
	}

	/**
	 * @override
	 */
	getType() {
		return 'generic';
	}

	/**
	 * @override
	 */
	isCheckbox() {
		return false;
	}

	/**
	 * @override
	 */
	register() {
		this.log(`Registering editable element "${this.instanceID}"...`);
		const xtwBody = this.xtwBody;
		if ( xtwBody instanceof XtwBody ) {
			const et = EditTarget.createInstance(this, this.columnId, this.rowId, this.inputId);
			if ( et ) {
				this._target = et;
				BscMgr.getInstance().setFocusHolder(this);
				return xtwBody.setEditTarget(et);
			}
		}
		return false;
	}

	/**
	 * @override
	 */
	unregister() {
		BscMgr.getInstance().removeFocusHolder(this);
		if ( this._target ) {
			const et = this._target;
			this._target = null;
			const xtwBody = this.xtwBody;
			if ( xtwBody instanceof XtwBody ) {
				return xtwBody.removeEditTarget(et);
			} else {
				this.log(`Editable element "${this.instanceID}" has no valid cell reference!`);
			}
		}
		return false;
	}

	get lastlyRegistered() {
		const xtwBody = this.xtwBody;
		if ( (xtwBody instanceof XtwBody) && (this._target instanceof EditTarget) ) {
			return this._target.equals(xtwBody.editTarget);
		}
		return false;
	}

	/**
	 * @override
	 */
	render() {
		if ( this.isTraceEnabled() ) {
			this.trace(`Rendering editable element "${this.instanceID}"...`);
		}
		this.register();
	}

	/**
	 * destroys the editable element
	 */
	destroySelf() {
		delete this._ovrOrgValue;
		delete this._row;
		delete this._column;
		delete this._cell;
		this.assumeDead();
	}

	/**
	 * @override
	 */
	destroySelfAndRestoreCell() {
		if ( !this.locked ) {
			if ( this.isTraceEnabled() ) {
				this.trace(`Instance ${this.instanceID} is about to destroy itself.`);
			}
			const xtb = this.xtwBody;
			const cell = this.cell;
			this.destroySelf();
			if ( cell instanceof XCellItem ) {
				cell.droppedDown = false;
				if ( (cell.alignElement instanceof HTMLElement) && (cell.cttElm instanceof HTMLElement) ) {
					cell.alignElement.appendChild( cell.cttElm );
				}
				if ( (xtb instanceof XtwBody) && xtb.hasRapFocus ) {
					const ce = cell.getDomElement();
					if ( ce instanceof HTMLElement ) {
						// set focus back to the cell, that's vital for event processing
						ce.focus( { preventScroll: true } );
					}

				}
			}
		}
		else {
			if ( this.isTraceEnabled() ) {
				this.trace(`Instance ${this.instanceID} is locked! Self-destruction rejected.`);
			}
		}
		return true;
	}

	/**
	 * @inheritdoc
	 * @override
	 * @param {Boolean} horz 
	 * @param {Boolean} up 
     * @param {KeyboardEvent} ke
	 */
	needsArrowKey(horz, up, ke) {
		if ( !!horz ) {
			// we want every horizontal arrow key stroke!
			return true;
		}
		if ( ke instanceof KeyboardEvent ) {
			switch ( ke.key ) {
			case 'Home':
			case 'End':
				return true;
			default:
				return false;
			}
		}
		return false;
	}

	get newContainer() {
		const container = window.document.createElement( "div" );
		container.classList.add( "rtp-input-container" );
		return container;
	}

	/**
	 * @returns {Boolean} the current "dirty" state
	 */
	get dirty() {
		return !!this._dirty;
	}

	/**
	 * sets the new "dirty" state
	 * @param {Boolean} newValue the new "dirty" state
	 */
	set dirty( newValue ) {
		this._dirty = !!newValue;
	}

	/**
	 * @returns {Boolean} true if this cell is in the dummy row
	 */
	get insertionDummy() {
		// abstract
		return false;
	}

	/**
	 * @returns {Boolean} true if this cell is in the dummy row and dirty
	 */
	get dirtyInsertionDummy() {
		return this.insertionDummy && this.dirty;
	}

	/**
	 * @returns {HTMLElement | null} the input element
	 */
	get input() {
		return this._input;
	}

	/**
	 * helper method, sets the input element
	 * @param {HTMLElement} input the input element
	 */
	_setInput(input) {
		this._input = input;
	}

	/**
	 * @returns {String} the current input value
	 */
	get inputValue() {
		// abstract
		return '';
	}

	/**
	 * @returns {HTMLInputElement} the actual input element
	 */
	get contentEditableElement() {
		// abstract
		return null;
	}

	get editingAllowed() {
		return !!this._editingAllowed && this.alive;
	}

	set editingAllowed( newValue ) {
		if ( Validator.isBoolean( newValue ) ) {
			this._editingAllowed = !!newValue && this.alive;
		}
		if ( this._editingAllowed ) {
			this.register();
		}
	}

	get element() {
		return this.container;
	}

	get cellElement() {
		if ( !(this.cell instanceof XCellItem) ) {
			return null;
		}
		const alignmentElement = this.cell.alignElement
		return alignmentElement instanceof HTMLElement ? alignmentElement : void 0;
	}

	/**
	 * @returns {XtwBody | null} the table's body part
	 */
	get xtwBody() {
		const cell = this.cell;
		return cell instanceof XCellItem ? cell.xtwBody : null;
	}

	get isRendered() {
		return this.container instanceof HTMLElement;
	}

	/**
	 * @returns {Boolean} true if this input element is read/only; false otherwise
	 */
	get isReadOnly() {
		if ( !this.editingAllowed ) {
			return true;
		}
		return (this.cell instanceof XCellItem) ? this.cell.isReadOnly : false;
	}

	/**
	 * @returns {Boolean} true if this is a password field; false otherwise
	 */
	get isPassword() {
		const ctt = this.cellContent;
		const prop = (ctt ? ctt.prop : null) || {};
		return !!prop.password;
	}

	get canBeEdited() {
		if ( !this.editingAllowed ) {
			return false;
		}
		return (this.cell instanceof XCellItem) ? this.cell.canBeEdited : false;
	}

	get shouldBeSkipped() {
		return (this.cell instanceof XCellItem) ? this.cell.shouldBeSkipped : false;
	}

	/**
	 * @returns {Boolean} true if there's an overridden original value; false otherwise
	 */
	get hasOvrOrgValue() {
		return this.alive && Validator.isString(this._ovrOrgValue);
	}

	/**
	 * @returns {String} the original content of the model cell
	 */
	get originalValue() {
		if ( !this.alive ) {
			return '';
		}
		if ( this.alive && (this._ovrOrgValue !== undefined) && (this._ovrOrgValue !== null) ) {
			return this._ovrOrgValue;
		}
		if ( this.cell instanceof XCellItem ) {
			return this.cell.getOrigCtt();
		} else {
			return '';
		}
	}

	/**
	 * @returns {CellCtt} the content object of the target cell
	 */
	get cellContent() {
		return (this.cell instanceof XCellItem) ? this.cell.ctt : null;
	}

	/**
	 * @returns {String} the content of the target cell
	 */
	get cellValue() {
		const ctt = this.cellContent;
		if ( ctt instanceof CellCtt ) {
			return ctt.plainText;
		} else {
			return '';
		}
	}

	get link() {
		return (this.cell instanceof XCellItem) ? !!this.cell.link : false;
	}

	get horizontalAlignment() {
		if ( !(this.cell instanceof XCellItem) ) {
			return null;
		}
		return this.cell.horizontalAlignment;
	}

	/**
	 * @inheritdoc
	 * @override
	 * @returns {Boolean}
	 */
	get droppedDown() {
		const cell = this.cell;
		return (cell instanceof XCellItem) && cell.alive ? !!cell.droppedDown : false;
	}


	/**
	 * discards the editing UI
	 * @returns {Boolean} true if something was discarded
	 */
	discardUi() {
		if ( this.isTraceEnabled() ) {
			this.trace(`Destroying editable element "${this.instanceID}"...`);
		}
		this.unregister();
		const inputDiscarded = this.discardInput();
		const containerDiscarded = this.discardContainer();
		return inputDiscarded && containerDiscarded;
	}

	discardInput() {
		let res = false;
		try {
			const input = this.input;
			if ( input instanceof HTMLElement ) {
				input.innerHTML = '';
				HtmHelper.rmvDomElm(input);
				res = true;
			}
		} finally {
			this._input = null;			
		}
		return res;
	}

	discardContainer() {
		return this._discardElementProperty( "container" );
	}

	_discardElementProperty( propertyName ) {
		if ( !Validator.isString( propertyName ) || !( propertyName in this ) ) {
			return false;
		}
		let element = this[ propertyName ];
		this[ propertyName ] = void 0;
		delete this[ propertyName ];
		if ( !( element instanceof HTMLElement ) ) {
			return true;
		}
		element.innerHTML = "";
		element.remove();
		element = void 0;
		return true;
	}

	get rowEdited() {
		const row = this.row;
		return Validator.isObject( row ) && !!row.edited;
	}

	set rowEdited( newValue ) {
		const row = this.row;
		if ( !Validator.isObject( row ) || !( "edited" in row ) ) {
			return;
		}
		row.edited = !!newValue;
	}

	informAboutEditing( parameters = {}, blockScreenRequest = false ) {
		this.log('Sending EDIT request');
		const result = this.informAboutInput( INPUT_EDITING_EVENT, parameters, !!blockScreenRequest );
		return result;
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	informAboutModified(parameters = {}, blockScreenRequest = false) {
		return this.informAboutInput(INPUT_MODIFIED_EVENT, parameters, !!blockScreenRequest);
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	informAboutSave( parameters = {}, blockScreenRequest = false ) {
		return this.informAboutInput( INPUT_SAVE_EVENT, parameters, !!blockScreenRequest );
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	informAboutCancel( parameters = {}, blockScreenRequest = false ) {
		return this.informAboutInput( INPUT_CANCEL_EVENT, parameters, !!blockScreenRequest );
	}

    /**
     * @inheritdoc
     * @override
     */
	informAboutFullSave( parameters = {}, blockScreenRequest = false ) {
		return this.informAboutInput( INPUT_FULL_SAVE_EVENT, parameters, !!blockScreenRequest );
	}

    /**
     * @inheritdoc
     * @override
     */
	createEditRequest() {
		return EditRequest.createInstance(this.columnId, this.rowId, this.insertMode, this.isKeepForced, this.inputValue);
	}

    /**
     * @inheritdoc
     * @override
     * @param {XCellItem} cell 
     */
	rebindToCell(cell) {
		super.rebindToCell(cell);
		if ( this.alive ) {
			// re-initialize!
			this._cell = cell;
			this._row = cell.row;
			this._column = cell.column;
		}
	}

	/**
	 * sends an "input" notification
	 * @param {String} inputBehaviorEvent the notification code
	 * @param {*} parameters parameter object
	 * @param {Boolean} blockScreenRequest block screen request flag
	 * @returns {Boolean} success / failure
	 */
	informAboutInput( inputBehaviorEvent, parameters = {}, blockScreenRequest = false ) {
		if ( !Validator.isObject( parameters ) ) {
			parameters = {};
		}
		if ( Validator.isString( inputBehaviorEvent ) ) {
			Object.assign( parameters, { event: inputBehaviorEvent } );
		}
		return this._nfySrv( INPUT_NOTIFICATION, parameters, !!blockScreenRequest );
	}

	/**
	 * @inheritdoc
	 * @override
	 * @param {String}
	 */
	setOvrOrgValue(ovr) {
		this._ovrOrgValue = ovr;
	}

	/**
	 * handles vertical arrow keystrokes
	 * @param {KeyboardEvent} domEvent the keyboard event
	 */
	onVerticalArrowKeyDown(domEvent) {
		// abstract
	}

	/**
	 * handles [Enter] keystrokes
	 * @param {KeyboardEvent} domEvent the keyboard event
	 */
	onInputEnter( domEvent ) {
		// abstract
	}

	/**
	 * handles [Esc] keystrokes
	 * @param {KeyboardEvent} domEvent the keyboard event
	 */
	onInputEscape( domEvent ) {
		// abstract
	}

	/**
	 * handles [Tab] keystrokes
	 * @param {KeyboardEvent} domEvent the keyboard event
	 */
	onInputTab( domEvent ) {
		// abstract
	}

	/**
	 * handles maximize requests
	 * @param {KeyboardEvent} domEvent the keyboard event
	 */
	handleMaximizeRequest(domEvent) {
		// abstract
	}

	/**
	 * saves modified data and keeps the editable
	 * @param {KeyboardEvent} domEvent 
	 * @returns {Boolean} true if successful; false otherwise
	 */
	saveAllAndKeepFocus(domEvent) {
		// abstract
		return false;
	}

	/**
	 * opens the associated dropdown (if available)
	 * @param {KeyboardEvent | MouseEvent} domEvent the DOM event; may be null
	 * @returns {Boolean} true if a dropdown was opened; false otherwise
	 */
	openDropdown( domEvent ) {
		// abstract
		return false;
	}

	_nfySrv( notificationCode, parameters = {}, blockScreenRequest = false ) {
		if ( !Validator.isString( notificationCode ) ) {
			return false;
		}
		const row = this.row;
		if ( !(row instanceof XRowItem) ) {
			return false;
		}
		if ( !Validator.isObject( parameters ) ) {
			parameters = {};
		}
		Object.assign( parameters, {
			idc: this.columnId,
			originalValue: this.originalValue,
			userValue: this.inputValue,
			inputId: this.inputId
		} );
		return row._nfySrv( notificationCode, parameters, !!blockScreenRequest );
	}

}
