import AttachmentObject from '../../../../utils/AttachmentObject';
import Validator from '../../../../utils/Validator';
import XtwTbl from '../../XtwTbl';

/** tooltip timeout in ms */
const TTP_TIMEOUT = 500;
/** tooltip space offset in pixels */
const SPACE_OFFS = 5;

export default class XtwColTooltipExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	/**
	 * @returns {String} the default tooltip
	 */
	get defaultTooltip() {
		return Validator.isString( this.ttip ) ? this.ttip : "";
	}

	get tableEndX() {
		return this.xtdTbl instanceof XtwTbl ? this.xtdTbl.tableEndX : void 0;
	}

	/**
	 * @returns {DOMRectReadOnly | null} the area of the whole table or null if not available
	 */
	get tableRect() {
		const table = this.xtdTbl;
		return table instanceof XtwTbl ? table.tableRect : null;
	}

	/**
	 * @returns {DOMRectReadOnly | null} the area of the column header element or null if not available
	 */
	get columnHeadRect() {
		const element = this.element;
		if ( element instanceof HTMLElement ) {
			return element.getBoundingClientRect();
		}
		return null;
	}

	/**
	 * @return {DOMRectReadOnly | null} the area of the tooltip element or null if not available
	 */
	get spanTooltipRect() {
		if ( !( this.spanTooltip instanceof HTMLElement ) ) {
			return null;
		}
		return this.spanTooltip.getBoundingClientRect();
	}

	_setAsActiveSumColumn() {
		const table = this.xtdTbl;
		if ( table instanceof XtwTbl ) {
			table.activeMouseoverTooltipSumColumn = this;
			return true;
		}
		return false;
	}

	_removeAsActiveSumColumn() {
		const table = this.xtdTbl;
		if ( (table instanceof XtwTbl) && (table.activeMouseoverTooltipSumColumn === this) ) {
			table.activeMouseoverTooltipSumColumn = void 0;
			delete table.activeMouseoverTooltipSumColumn;
			return true;
		}
		return false;
	}

	addTooltipListeners() {
		const mouseEnterListenerAdded = this.addListener("mouseenter", "_onMouseEnter");
		const mouseLeaveListenerAdded = this.addListener("mouseleave", "_onMouseLeave");
		return mouseEnterListenerAdded && mouseLeaveListenerAdded;
	}

	removeTooltipListeners() {
		this.removeListener("mouseenter");
		this.removeListener("mouseleave");
	}

	/**
	 * "mouseenter" listener
	 * @param {MouseEvent} domEvent 
	 */
	_onMouseEnter( domEvent ) {
		this._onMouseLeave();
		this._removeExistingTooltips();
		if ( domEvent.buttons > 0 ) {
			// a mouse button is pressed --> NO tooltip
			return;
		}
		const has_ttp = Validator.isString(this.defaultTooltip) || ((this.type === 1) && this.xtwHead.isColSumEnabled);
		if ( !has_ttp ) {
			// avoid showing empty tooltips
			return;
		}
		if ( this._createTooltipSpan(domEvent.clientX, domEvent.clientY) ) {
			const self = this;
			window.setTimeout( () => {
				self._showTooltipSpan();
			}, TTP_TIMEOUT );
		}
		this.tooltipSum = void 0;
		delete this.tooltipSum;
		this._removeAsActiveSumColumn();
		if ( !this.select && (this.type === 1) ) {
			// numeric field - check for "column sum"
			this.notifyServer( "columnHover" );
		}
	}

	_removeExistingTooltips() {
		const table = this.xtdTbl;
		return table instanceof XtwTbl ? table.removeAllColumnTooltips() : false;
	}

	/**
	 * "mouseleave" listener
	 * @param {MouseEvent} domEvent 
	 */
	_onMouseLeave( domEvent ) {
		this.tooltipSum = void 0;
		delete this.tooltipSum;
		this._destroyTooltipSpan();
		this._removeAsActiveSumColumn();
	}

	/**
	 * creates the tooltip span element
	 * @param {Number} mouse_x current x coordinate of mouse cursor
	 * @param {Number} mouse_y current y coordinate of mouse cursor
	 * @returns {Boolean} true if successful; false otherwise
	 */
	_createTooltipSpan(mouse_x, mouse_y) {
		if ( !this.isRendered ) {
			return false;
		}
		this.element.title = "";
		const span = this.newSpanTooltip;
		this.spanTooltip = span;
		this.element.appendChild( this.spanTooltip );
		// place the span
		this._placeTooltipSpan(span, mouse_x, mouse_y);
		// make it invisible at this moment
		span.classList.add( "invisible" );
		return true;
	}

	/**
	 * places the tooltip element
	 * @param {HTMLElement} span the tooltip element
	 * @param {Number} mouse_x current x coordinate of mouse cursor
	 * @param {Number} mouse_y current y coordinate of mouse cursor
	 */
	_placeTooltipSpan(span, mouse_x, mouse_y) {
		const tbl_rect = this.tableRect;
		const col_rect = this.columnHeadRect;
		const ttp_rect = this.spanTooltipRect;
		if ( (tbl_rect instanceof DOMRectReadOnly) && (col_rect instanceof DOMRectReadOnly) && (ttp_rect instanceof DOMRectReadOnly) ) {
			const ttp_width = ttp_rect.width;
			const ttp_height = ttp_rect.height + SPACE_OFFS;
			let top = Math.max(col_rect.bottom + SPACE_OFFS, mouse_y + SPACE_OFFS);
			let left = (col_rect.left + col_rect.right) / 2  - ttp_width / 2;
			if ( (top + ttp_height) > tbl_rect.bottom ) {
				top = tbl_rect.top;
			}
			if ( left < tbl_rect.x ) {
				left = tbl_rect.x;
			}
			if ( (left + ttp_width) > tbl_rect.right ) {
				left = tbl_rect.right - ttp_width;
			}
			span.style.left = `${left}px`;
			span.style.width = `${ttp_width}px`;
			span.style.top = `${top}px`;
		} else {
			span.style.top = `${(mouse_y + SPACE_OFFS)}px`;
			span.style.left = `${(mouse_x + SPACE_OFFS)}px`;
		}
	}

	/**
	 * shows the tooltip element
	 */
	_showTooltipSpan() {
		if ( !this.isRendered || !( this.spanTooltip instanceof HTMLElement ) ) {
			return;
		}
		this.spanTooltip.classList.remove("invisible");
	}

	/**
	 * destroys the tooltip element
	 * @returns {Boolean} true if successful
	 */
	_destroyTooltipSpan() {
		if ( this.isRendered ) {
			this.element.title = this.defaultTooltip;
		}
		let element = this.spanTooltip;
		this.spanTooltip = void 0;
		delete this.spanTooltip;
		if ( !( element instanceof HTMLElement ) ) {
			return true;
		}
		element.innerHTML = "";
		element.remove();
		element = void 0;
		return true;
	}

	get newSpanTooltip() {
		const spanTooltip = window.document.createElement( "span" );
		spanTooltip.classList.add( "xtwheader-tooltip" );
		spanTooltip.role = "tooltip";
		spanTooltip.innerHTML = this.defaultTooltip;
		const main_span = this.mainSpan;
		if ( main_span instanceof HTMLElement ) {
			spanTooltip.style.fontFamily = main_span.style.fontFamily;
			spanTooltip.style.fontSize = main_span.style.fontSize;
			spanTooltip.style.fontWeight = main_span.style.fontWeight;
		}
		return spanTooltip;
	}

	_addToSpanTooltip( addition ) {
		if ( !( this.spanTooltip instanceof HTMLElement ) ) {
			return false;
		}
		let ttp = this.defaultTooltip;
		if ( Validator.isString(addition) ) {
			if ( Validator.isString(ttp) ) {
				ttp += '<br/>';
			}
			ttp += addition;
		}
		this.spanTooltip.innerHTML = ttp;
		return true;
	}

	setTooltip( newTooltipValue ) {
		if ( !Validator.isString( newTooltipValue ) ) {
			return false;
		}
		if ( this.element instanceof HTMLElement ) {
			this.element.title = newTooltipValue;
		}
		return true;
	}

	addSumToTooltip( sum ) {
		if ( this.type !== 1 ) { // only if the type is equal to 1, then it is a numeric field
			return false;
		}
		if ( Validator.isString(sum) ) {
			this.tooltipSum = sum;
			this._setAsActiveSumColumn();
			return this._addToSpanTooltip( 'Σ = ' + sum );
		} else {
			this.tooltipSum = null;
			if ( !Validator.isString(this.defaultTooltip) ) {
				this.onMouseLeave();
			}
			return this._addToSpanTooltip('');
		}
	}

	handleCopyTooltipSumRequest() {
		if ( this.type !== 1 ) { // only if the type is equal to 1, then it is a numeric field
			return false;
		}
		this.notifyServer( "copyTooltipSum", { tooltipSum: this.tooltipSum } );
		return true;
	}

	makeSureTableIsFocused() {
		const table = this.xtdTbl;
		if ( !Validator.isFunctionPath( table, "table.makeSureTableIsFocused" ) ) {
			return false;
		}
		return table.makeSureTableIsFocused();
	}

}
