// import Color from '../../../../utils/Color';
import DomEventHelper from '../../../../utils/DomEventHelper';
import EventListenerManager from '../../../../utils/EventListenerManager';
import HtmHelper from '../../../../utils/HtmHelper';
import EditingElementSelectionManagerExtension from '../editing/EditingElementSelectionManagerExtension';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';

const CONTEXTMENU_INPUT_HEIGHT = 32;
const SEARCH_ICON_SPAN_WIDTH = 32;
export const FIXED_AREA_SEPARATOR_HEIGHT = "1.5";
export const FIXED_AREA_SEPARATOR_COLOR = "#c8c8c8";

export default class SearchInputMenuItem {

	constructor( menuObject ) {
		this.menuObject = menuObject;
		menuObject.searchInputMenuItem = this;
		new EditingElementSelectionManagerExtension( this );
		this.render();
	}

	get height() {
		return CONTEXTMENU_INPUT_HEIGHT;
	}

	get contentEditableElement() {
		return this.input;
	}

	get currentContentLength() {
		return this.value.length;
	}

	get menuManager() {
		return Validator.isObjectPath( this.menuObject, "menuObject.mnuMgr" ) ?
			this.menuObject.mnuMgr : void 0;
	}

	get visibleMenuItems() {
		return Validator.isObject( this.menuObject ) &&
			"visibleMenuItems" in this.menuObject ?
			this.menuObject.visibleMenuItems : void 0;
	}

	get element() {
		return this.container;
	}

	get isRendered() {
		return [ this.container, this.searchIcon, this.input, this.cancelButton ].every( element => element instanceof HTMLElement );
	}

	get value() {
		if ( !( this.input instanceof HTMLElement ) ) {
			return "";
		}
		return Validator.isString( this.input.value ) ? this.input.value : "";
	}

	set value( newInputValue ) {
		if ( !( this.input instanceof HTMLElement ) ||
			typeof newInputValue != "string" ) {
			return;
		}
		this.input.value = newInputValue;
		if ( !( this.cancelButton instanceof HTMLElement ) ) {
			return;
		}
		newInputValue.length < 1 ? this.cancelButton.classList.remove( "active" ) : this.cancelButton.classList.add( "active" );
	}

	hide() {
		return this._setContainerVisibility( false );
	}

	show() {
		return this._setContainerVisibility( true );
	}

	get isVisible() {
		return !( this.container instanceof HTMLElement ) ? false : !this.container.classList.contains( "invisible" );
	}

	focus() {
		if ( !( this.input instanceof HTMLElement ) || !this.isVisible ) {
			return false;
		}
		this.input.tabIndex = 0;
		this.input.focus();
		return true;
	}

	blur() {
		if ( !( this.input instanceof HTMLElement ) ) {
			return false;
		}
		this.input.blur();
		return true;
	}

	render() {
		this.discardUi();
		this.container = this.newContainerDiv;
		this.searchIcon = this.newSearchIconSpan;
		this.input = this.newInput;
		this.cancelButton = this.newCancelButton;
		[ this.searchIcon, this.input, this.cancelButton ].forEach( element => {
			this.container.appendChild( element );
		} );
		return true;
	}

	informAboutInputChange() {
		const informedAboutInputChange = this._informAboutInputChange();
		this.focus();
		return informedAboutInputChange;
	}

	_informAboutInputChange() {
		return Validator.isFunctionPath( this.menuObject,
				"menuObject.reactToInputChange" ) ?
			this.menuObject.reactToInputChange() : false;
	}

	setSelectionToEnd() {
		const currentContentLength = this.currentContentLength;
		this.setSelection( currentContentLength, currentContentLength,
			"forward" );
		return true;
	}

	set allMenuItemsAreHidden( theMenusAreHidden ) {
		if ( !Validator.isObject( this.menuObject ) ) {
			return;
		}
		this.menuObject.allMenuItemsAreHidden = !!theMenusAreHidden;
	}

	get allMenuItemsAreHidden() {
		return Validator.isObject( this.menuObject ) ?
			this.menuObject.allMenuItemsAreHidden : void 0;
	}

	removeSelf() {
		this.discardUi();
		if ( !Validator.isObject( this.menuObject ) ) {
			return false;
		}
		if ( this.menuObject.searchInputMenuItem != this ) {
			return true;
		}
		this.menuObject.searchInputMenuItem = void 0;
		delete this.menuObject.searchInputMenuItem;
		return true;
	}

	get searchInColumnsMenuOptionTitle() {
		return Validator.isObject( this.menuObject ) &&
			"searchInColumnsMenuOptionTitle" in this.menuObject ?
			this.menuObject.searchInColumnsMenuOptionTitle : void 0;
	}

	discardUi() {
		const searchIconDiscarded = this.discardSearchIcon();
		const inputDiscarded = this.discardInput();
		const cancelButtonDiscarded = this.discardCancelButton();
		const containerDiscarded = this.discardContainer();
		return searchIconDiscarded && inputDiscarded && cancelButtonDiscarded && containerDiscarded;
	}

	discardContainer() {
		return HtmHelper.discardElementProperty( this, "container" );
	}

	discardSearchIcon() {
		return HtmHelper.discardElementProperty( this, "searchIcon" );
	}

	discardInput() {
		if ( this.input instanceof HTMLElement ) {
			[ "keydown", "keyup", "keypress", "contextmenu", "paste" ].forEach( eventName => {
				EventListenerManager.removeListener( this, eventName, this.input, "Input" );
			} );
		}
		return HtmHelper.discardElementProperty( this, "input" );
	}

	discardCancelButton() {
		return HtmHelper.discardElementProperty( this, "cancelButton" );
	}

	get newContainerDiv() {
		const displayDiv = window.document.createElement( "div" );
		displayDiv.style.setProperty( "--rtp-contextmenu-key-input-display-height",
			`${ CONTEXTMENU_INPUT_HEIGHT }px` );
		displayDiv.classList.add( "contextmenu-key-input-display", "invisible" );
		const menuManager = this.menuManager;
		if ( !Validator.isObject( menuManager ) ) {
			return displayDiv;
		}
		displayDiv.style.borderBottom = `${ FIXED_AREA_SEPARATOR_HEIGHT }px solid` +
			` ${ FIXED_AREA_SEPARATOR_COLOR }`;
		return displayDiv;
	}

	get newSearchIconSpan() {
		const searchIconSpan = window.document.createElement( "span" );
		searchIconSpan.classList.add( "search" );
		searchIconSpan.style.setProperty(
			"--rtp-contextmenu-key-input-display-search-width",
			`${ SEARCH_ICON_SPAN_WIDTH }px` )
		const searchIconItalicTag = window.document.createElement( "i" );
		searchIconItalicTag.classList.add( "fa", "fa-search" );
		searchIconSpan.appendChild( searchIconItalicTag );
		return searchIconSpan;
	}

	get newCancelButton() {
		const cancelButtonDiv = window.document.createElement( "div" );
		cancelButtonDiv.classList.add( "close" );
		cancelButtonDiv.style.setProperty(
			"--rtp-contextmenu-key-input-display-close-width",
			`${ SEARCH_ICON_SPAN_WIDTH }px` )
		this.addCancelButtonListeners( cancelButtonDiv );
		const cancelIconItalicTag = window.document.createElement( "i" );
		cancelIconItalicTag.classList.add( "fa", "fa-close" );
		cancelButtonDiv.appendChild( cancelIconItalicTag );
		return cancelButtonDiv;
	}

	get newInput() {
		const input = window.document.createElement( 'input' );
		this.addInputListeners( input );
		input.placeholder = this.searchInColumnsMenuOptionTitle || "Spalten filtern";
		input.type = "text";
		return input;
	}

	refreshInputPlaceholder() {
		if ( !( this.input instanceof HTMLElement ) ) {
			return false;
		}
		this.input.placeholder = this.searchInColumnsMenuOptionTitle ||
			"Spalten filtern";
		return true;
	}

	_setContainerVisibility( setToVisible = true ) {
		if ( !( this.container instanceof HTMLElement ) ) {
			return false;
		}
		setToVisible ? this.container.classList.remove( "invisible" ) :
			this.container.classList.add( "invisible" );
		return true;
	}

	addInputListeners( input ) {
		const clickListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "click",
			functionName: "onInputClick",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		const keydownListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keydown",
			functionName: "onInputKeyDown",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		const keyupListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keyup",
			functionName: "onInputKeyUp",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		const keypressListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keypress",
			functionName: "onInputKeyPress",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		const contextmenuListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "contextmenu",
			functionName: "onInputContextMenu",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		const pasteListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "paste",
			functionName: "onInputPaste",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		const selectListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "select",
			functionName: "onInputSelect",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		return clickListenerSuccessfullyAdded && keydownListenerSuccessfullyAdded &&
			keyupListenerSuccessfullyAdded && keypressListenerSuccessfullyAdded &&
			contextmenuListenerSuccessfullyAdded && pasteListenerSuccessfullyAdded &&
			selectListenerSuccessfullyAdded;
	}

	addCancelButtonListeners( cancelButton ) {
		const clickListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "click",
			functionName: "onCancelButtonClick",
			callBackPrefix: "CancelButton",
			element: cancelButton,
			useCapture: false
		} );
		return clickListenerSuccessfullyAdded;
	}

	onInputClick( domEvent ) {
		if ( domEvent instanceof MouseEvent ) {
			domEvent.preventDefault();
		}
		this.setSelectionToEnd();
		this.focus();
	}

	onInputKeyDown( domEvent ) {
		if ( !this.isVisible ) {
			return;
		}
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.preventDefault();
		}
		Warner.traceIf( true );
	}

	onInputKeyUp( domEvent ) {
		if ( !this.isVisible ) {
			return;
		}
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.preventDefault();
		}
		if ( DomEventHelper.keyIs( domEvent, "Delete" ) ) {
			return this.onCancelButtonClick( domEvent );
		}
		if ( DomEventHelper.keyIs( domEvent, "Space" ) ) {
			return this.onInputSpace( domEvent );
		}
		if ( DomEventHelper.isArrowDown( domEvent ) ) {
			return this.onArrowDown( domEvent );
		}
		if ( DomEventHelper.isArrowUp( domEvent ) ) {
			return this.onArrowUp( domEvent );
		}
		Warner.traceIf( true );
	}

	onInputKeyPress( domEvent ) {
		if ( !this.isVisible ) {
			return;
		}
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.preventDefault();
		}
		Warner.traceIf( true );
	}

	onInputContextMenu( domEvent ) {
		// DomEventHelper.stopIf( domEvent );
		Warner.traceIf( true );
	}

	onInputPaste( domEvent ) {
		// DomEventHelper.stopIf( domEvent );
		Warner.traceIf( true );
	}

	onInputSelect( domEvent ) {
		DomEventHelper.stopIf( domEvent );
		this.setSelectionToEnd();
		this.focus();
	}

	onCancelButtonClick( domEvent ) {
		this.value = "";
		return this.informAboutInputChange();
	}

	onInputSpace( domEvent ) {
		DomEventHelper.stopIf( domEvent );
		if ( this.allMenuItemsAreHidden ) {
			return false;
		}
		const value = this.value;
		if ( value.length < 1 ) {
			return false; //no menu items start with a whitespace character
		}
		this.value = value + " ";
		return this.informAboutInputChange();
	}

	onArrowUp( domEvent ) {
		DomEventHelper.stopIf( domEvent );
		return true;
	}

	onArrowDown( domEvent ) {
		DomEventHelper.stopIf( domEvent );
		if ( !Validator.isFunctionPath( this.menuObject,
				"menuObject.mnuMgr.curHdl.onHeaderContextMenuHome" ) ) {
			return false;
		}
		this.blur();
		this.menuObject.mnuMgr.curHdl.onHeaderContextMenuHome();
		return true;
	}

}
