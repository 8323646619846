import LoggingBase from "../../../../base/loggingbase";
import Validator from "../../../../utils/Validator";

export default class EditRequest extends LoggingBase {

    /**
     * constructs a new instance
     * @param {Number} cid column ID of the new edit target
     * @param {Number} rid row ID of the new edit target
     * @param {Boolean} mode insert mode flag
     * @param {Boolean} keep "keep forced" flag
     * @param {String|null} value optional value
     */
    constructor(cid, rid, mode, keep, value) {
        super('widgets.xtw.editing.EditRequest');
        this._cid = cid;
        this._rid = rid;
        this._mode = !!mode;
        this._keep = !!keep;
        this._value = Validator.isString(value) ? value : null;
        this._dropdown = false;
        this._deferred = false;
        this._valid = true;
    }

    /**
     * @returns {String} a string representation
     */
    toString() {
        if ( this.alive ) {
            return `{ER: cid=${this.columnId}, rid=${this.rowId}, ins=${this.insertMode}, keep=${this.isKeepForced}, dropdown=${this.dropdown} value="${Validator.ensureString(this.value)}"}`;
        } else {
            return 'Dead instance!';
        }
    }

    /**
     * @override
     */
    doDestroy() {
        delete this._cid;
        delete this._rid;
        this._valid = false;
        super.doDestroy();
    }

    /**
     * @returns {Number} the column ID of the new edit target
     */
    get columnId() {
        return this._cid;
    }

    /**
     * @returns {Number} the row ID of the new edit target
     */
    get rowId() {
        return this._rid;
    }

	/**
	 * @returns {Boolean} true if the current edit mode is an "insert" mode; false otherwise
	 */
    get insertMode() {
        return this._mode;
    }

    /**
     * @returns {Boolean} true if this instance should be kept on any data operation; false for common behavior
     */
    get isKeepForced() {
        return this._keep;
    }

    /**
     * @returns {Boolean} true if a new cell editor should enter "dropped down" state immediately
     */
    get dropdown() {
        return this._dropdown;
    }

    /**
     * @param {Boolean} drp "dropped down" state
     */
    set dropdown(drp) {
        this._dropdown = !!drp;
    }

    /**
     * @returns {String | null} the stored value or null
     */
    get value() {
        return this._value;
    }

    /**
     * @returns {Boolean} true if this edit request should be handled in a "deferred" cycle; false otherwise
     */
    get deferred() {
        return this._deferred;
    }

    /**
     * @param {Boolean} df new "deferred" state
     */
    set deferred(df) {
        this._deferred = !!df;
    }

    /**
     * @returns {Boolean} true if this instance is valid; false otherwise
     */
    get valid() {
        return this.alive && this._valid;
    }

    /**
     * invalidates this instance
     */
    invalidate() {
        this._valid = false;
        this._cid = -1;
        this._rid = -1;
    }

    /**
     * creates a new instance
     * @param {Number} cid column ID of the new edit target
     * @param {Number} rid row ID of the new edit target
     * @param {Boolean} mode insert mode flag
     * @param {Boolean} keep "keep forced" flag
     * @param {String|null} value optional value
     * @returns {EditRequest | null} the created instance or null if parameters are invalid
     */
    static createInstance(cid, rid, mode, keep, value = null) {
        if ( !Validator.isStringOrNumber(cid) || !Validator.isStringOrNumber(rid) ) {
            return null;
        }
        if ( (Validator.isNumber(cid) && (cid <= 0)) || (Validator.isNumber(rid) && (rid <= 0)) ) {
            return null;
        }
        return new EditRequest(cid, rid, mode, keep, value);
    }
}