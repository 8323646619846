import DomEventHelper from '../../../utils/DomEventHelper';
import EventListenerManager from '../../../utils/EventListenerManager';
import ObjReg from '../../../utils/ObjReg';
import Validator from '../../../utils/Validator';
import Warner from '../../../utils/Warner';

export default class XtwRowTemplateItem {

	constructor( parentObject, parameters ) {
		this._creationParameters = {
			parentObject: parentObject,
			parameters: parameters
		};
		this.children = new ObjReg();
		this.create();
	}

	create() {
		this.hasContent = false;
		if ( !Validator.isObjectPath( this, "this._creationParameters.parentObject" ) ||
			!Validator.isObject( this._creationParameters.parameters ) ) return false;

		const parentObject = this._creationParameters.parentObject;
		const parameters = this._creationParameters.parameters;

		[ "isRowGroupContainer", "isRowGroup", "isRow", "isRowChildUnit", "isCellContentContainer" ]
		.forEach( property => {
			this._defineFalseGetterProperty( property );
		} );

		Object.defineProperty( this, "parentObject", {
			configurable: true,
			get: () => {
				return Validator
					.isObjectPath( this, "this._creationParameters.parentObject" ) ?
					this._creationParameters.parentObject : void 0;
			},
			set: () => {
				Warner._trace( `The value of the property "parentObject" can not be` +
					` modified.` );
			}
		} );

		Validator.unmodifiableGetter( {
			hostObject: this,
			getterName: "isRowTpl",
			getCallback: () => {
				const parentObject = this.parentObject;
				if ( !Validator.isObject( parentObject ) ) return false;
				return parentObject.isRowTpl;
			}
		} );

		Validator.unmodifiableGetter( {
			hostObject: this,
			getterName: "parentObjectElement",
			getCallback: () => {
				const parentObject = this.parentObject;
				if ( !Validator.isObject( parentObject ) ) return void 0;
				return parentObject.element instanceof HTMLElement ?
					parentObject.element : void 0;
			}
		} );

		Validator.unmodifiableGetter( {
			hostObject: this,
			getterName: "parentObjectId",
			getCallback: () => {
				const parentObject = this.parentObject;
				if ( !Validator.isObject( parentObject ) ) return void 0;
				return Validator.isString( parentObject.itemId ) ?
					parentObject.itemId : void 0;
			}
		} );

		if ( Validator.isString( parameters.itemId ) )
			Object.defineProperty( this, "itemId", {
				value: parameters.itemId,
				writable: false
			} );

		return true;
	}

	_defineBooleanGetterProperty( propertyName, booleanValue ) {
		if ( !Validator.isString( propertyName ) ) return false;
		if ( !Validator.isBoolean( booleanValue ) ) return false;
		Object.defineProperty( this, propertyName, {
			configurable: true,
			get: () => { return booleanValue },
			set: () => {
				Warner._trace( `The value of the property "${ propertyName }"` +
					` can not be modified.` );
			}
		} );
		return true;
	}

	get bodyParentObject() {
		const parentObject = this.parentObject;
		if ( !Validator.isObject( parentObject ) ) return void 0;
		if ( Validator.is( parentObject, "XtwBody" ) ) return parentObject;
		// if ( !( "bodyParentObject" in parentObject ) ) return void 0;
		return parentObject.bodyParentObject;
	}

	_defineFalseGetterProperty( propertyName ) {
		return this._defineBooleanGetterProperty( propertyName, false );
	}

	_defineTrueGetterProperty( propertyName ) {
		return this._defineBooleanGetterProperty( propertyName, true );
	}

	addChild( child, childItemId = null, childItemIdPrefix = null ) {
		if ( !Validator.isObject( child ) ) {
			return false;
		}
		if ( Validator.isPositiveInteger( childItemId ) ) {
			childItemId = String( childItemId );
			child.itemId = childItemId;
		} else if ( !Validator.isString( childItemId ) ) {
			childItemId = child.itemId;
			if ( !Validator.isString( childItemId ) ) {
				if ( !Validator.isString( childItemIdPrefix ) ) {
					childItemIdPrefix = "rtp-item-";
				}
				childItemId = Validator.generateRandomString( childItemIdPrefix );
			}
		}
		this.children.addObj( childItemId, child );
		if ( this.element instanceof HTMLElement &&
			Validator.isFunction( child.render ) ) {
			child.render();
		}
		return true;
	}

	getChild( childItemId ) {
		if ( !Validator.isString( childItemId ) ) {
			return void 0;
		}
		return this.children.getObj( childItemId );
	}

	removeSelf() {
		this.removeChildren();
		this.removeOwnElement();
	}

	removeOwnElement() {
		if ( !this.isRendered ) return;
		// this.removeClickListener();
		let element = this.element;
		this.element = void 0;
		delete this.element;
		element.innerHTML = "";
		element.remove();
		element = void 0;
	}

	removeChildren() {
		let children = this.children.getValues();
		if ( !Validator.isArray( children ) ) {
			return this.removeElementChildren();
		}
		for ( let child of children ) {
			if ( !Validator.isFunction( child.removeSelf ) ) {
				continue;
			}
			child.removeSelf();
		}
		this.children = new ObjReg();
		this.removeElementChildren();
	}

	removeElementChildren() {
		if ( !this.isRendered ) {
			return;

		}
		for ( let childNode of this.element.childNodes ) {
			// childNode.remove();
			childNode.innerHTML = "";
			this.element.removeChild( childNode );
		}
		this.element.innerHTML = "";
	}

	renderAs( htmlElementToAssign, parentElement = null ) {
		if ( !( htmlElementToAssign instanceof HTMLElement ) ) return;
		if ( this.element instanceof HTMLElement ) return this.renderChildren();
		if ( !( parentElement instanceof HTMLElement ) )
			parentElement = this.parentObjectElement;
		if ( !( parentElement instanceof HTMLElement ) ) return;
		this.element = htmlElementToAssign;
		this.addDatasetProperties();
		// !this.hasContent ? this._setInvisible() : this._setVisible();
		parentElement.appendChild( this.element );
		this.renderChildren();
	}

	addDatasetProperties() {
		if ( !this.isRendered ||
			!Validator.is( this.element.dataset, "DOMStringMap" ) ) return;
		if ( Validator.isString( this.itemId ) )
			this.element.dataset.id = this.itemId;
		const className = Validator.getClassName( this );
		if ( Validator.isString( className ) )
			this.element.dataset.class = className;
		const parameters = Validator.isObjectPath( this,
				"this._creationParameters.parameters" ) ?
			this._creationParameters.parameters : void 0;
		if ( Validator.isObject( parameters ) &&
			Validator.isPositiveNumber( parameters.position ) )
			this.element.dataset.position = parameters.position;
	}

	render( parentElement = null ) {
		if ( Validator.isString( this.predefinedGetterElement ) )
			return this.renderAs( this[ this.predefinedGetterElement ], parentElement );
		if ( this.predefinedElement instanceof HTMLElement )
			return this.renderAs( this.predefinedElement, parentElement );
		if ( this.isCellContentContainer )
			return this.renderAs( this.newGridItem, parentElement );
		if ( this.isRow )
			return this.renderAs( this.newHorizontalGrid, parentElement );
		if ( this.isRowGroup )
			return this.renderAs( this.newVerticalGrid, parentElement );
		if ( this.isRowGroupContainer )
			return this.renderAs( this.newGridContainer, parentElement );
	}

	renderChildren() {
		let children = this.children.getValues();
		if ( !Validator.isArray( children ) ) {
			return false;
		}
		for ( let child of children ) {
			if ( !Validator.isObject( child ) || !Validator.isFunction( child.render ) ) {
				continue;
			}
			child.render();
		}
		return true;
	}

	ruin() {
		delete this.parentObject;
		this.removeSelf();
	}

	destroy() {
		this.ruin();
		delete this._creationParameters;
	}

	show() {
		this.hasContent = true;
		if ( !this.isRendered ) {
			this.render();
			if ( !this.isRendered ) return;
		}
		this._setVisible();
		let parentObject = this.parentObject;
		if ( !Validator.isObject( parentObject ) ||
			!Validator.isFunction( parentObject.show ) ) return;
		parentObject.show();
	}

	_setVisible() {
		if ( !this.isRendered ) return false;
		this.element.classList.remove( "rtp-hidden" );
		return true;
	}

	_setInvisible() {
		if ( !this.isRendered ) return false;
		this.element.classList.add( "rtp-hidden" );
		return true;
	}

	get isRendered() {
		return this.element instanceof HTMLElement;
	}

	get newGridContainer() {
		let gridContainer = document.createElement( "div" );
		[ "rtp-grid", "rtp-y-scroll" ].forEach( className => {
			gridContainer.classList.add( className );
		} );
		return gridContainer;
	}

	_getNewGrid( horizontal = false ) {
		let classes = [ "rtp-grid" ];
		classes.push( !horizontal ? "vertical" : "horizontal" );
		let gridElement = document.createElement( "div" );
		classes.forEach( className => {
			gridElement.classList.add( className );
		} );
		return gridElement;
	}

	get newHorizontalGrid() {
		return this._getNewGrid( true );
	}

	get newVerticalGrid() {
		return this._getNewGrid( false );
	}

	get newGridItem() {
		let cellElement = document.createElement( "div" );
		[ "rtp-grid-item", "rtp-flexbox" ].forEach( ( className ) => {
			cellElement.classList.add( className );
		} );
		return cellElement;
	}

	get newCellContentItem() {
		let cellContentItemElement = document.createElement( "div" );
		cellContentItemElement.classList.add( "rtp-grid-item-content" );
		return cellContentItemElement;
	}

	get xtdTbl() {
		let parentObject = this.parentObject;
		if ( !Validator.isObject( parentObject ) ) return void 0;
		let xtdTbl = parentObject.xtdTbl;
		return Validator.is( xtdTbl, "XtwTbl" ) ? xtdTbl : void 0;
	}

	get xtwHead() {
		let parentObject = this.parentObject;
		if ( !Validator.isObject( parentObject ) ) return void 0;
		let xtwHead = parentObject.xtwHead;
		if ( Validator.is( xtwHead, "XtwHead" ) ) return xtwHead;
		let xtdTbl = this.xtdTbl;
		xtwHead = Validator.isObjectPath( xtdTbl, "xtdTbl.wdgHead" ) ?
			xtdTbl.wdgHead : void 0;
		return Validator.is( xtwHead, "XtwHead" ) ? xtwHead : void 0;
	}

	get xtwBody() {
		let xtwHead = this.xtwHead;
		let xtwBody = Validator.isObjectPath( xtwHead, "xtwHead.xtwBody" ) ?
			xtwHead.xtwBody : void 0;
		if ( Validator.is( xtwBody, "XtwBody" ) ) return xtwBody;
		let xtdTbl = this.xtdTbl;
		xtwBody = Validator.isObjectPath( xtdTbl, "xtdTbl.wdgBody" ) ?
			xtdTbl.wdgBody : void 0;
		if ( Validator.is( xtwBody, "XtwBody" ) ) return xtwBody;
		return void 0;
	}

	get rcells() {
		let parentObject = this.parentObject;
		if ( !Validator.isObject( parentObject ) ) return void 0;
		let rcells = parentObject.rcells;
		return Validator.isMap( rcells ) ? rcells : void 0;
	}

	get itmMgr() {
		if ( !Validator.isObject( pisasales ) || !Validator.isFunction( pisasales.getItmMgr ) ) {
			return void 0;
		}
		const itemManager = pisasales.getItmMgr();
		return Validator.is( itemManager, "ItmMgr" ) ? itemManager : void 0;
	}

	get idManager() {
		let parentObject = this.parentObject;
		if ( !Validator.isObject( parentObject ) ) return void 0;
		let idManager = parentObject.idManager;
		return Validator.isObject( idManager ) ? idManager : void 0;
	}

	get rowTemplateDefinition() {
		const parentObject = this.parentObject;
		if ( Validator.isObject( parentObject ) ) {
			return parentObject.getRowTpl;
		} else {
			return null;
		}
	}

	get isNewRowTemplate() {
		const definition = this.rowTemplateDefinition;
		if ( !Validator.isObject( definition ) || !Validator.isBoolean( definition.rtpNew ) ) {
			return false;
		}
		return definition.rtpNew;
	}

	addClasses( classNameOrList, element = null ) {
		if ( !( element instanceof HTMLElement ) ) {
			if ( !this.isRendered ) return;
			element = this.element;
		};
		if ( Validator.isString( classNameOrList ) )
			classNameOrList = [ classNameOrList ];
		if ( !Validator.isArray( classNameOrList ) ) return;
		for ( let className of classNameOrList ) {
			if ( !Validator.isString( className ) ) continue;
			element.classList.add( className );
		}
	}

	_nfySrv( eventName, parameters = void 0 ) {
		if ( !Validator.isString( eventName ) ) return false;
		const parentObject = this.parentObject;
		if ( !Validator.isObject( parentObject ) ||
			!Validator.isFunction( parentObject._nfySrv ) ) return false;
		if ( !Validator.isObject( parameters ) )
			parameters = {};
		return parentObject._nfySrv( eventName, parameters );
	}

	addListener( eventName, functionName ) {
		return EventListenerManager.addListener( {
			instance: this,
			eventName: eventName,
			functionName: functionName
		} );
	}

	removeListener( eventName ) {
		return EventListenerManager.removeListener( this, eventName );
	}

	addClickListener() {
		return this.addListener( "click", "onClick" );
	}

	removeClickListener() {
		return this.removeListener( "click" );
	}

	onClick( evt, parameters = void 0 ) {
		evt.stopPropagation();
		evt.preventDefault();
		const parentObject = this.parentObject;
		if ( !Validator.isObject( parentObject ) ||
			!Validator.isFunction( parentObject.onClick ) ) return;
		parentObject.onClick( evt, parameters );
	}

	addContextMenuListener() {
		return this.addListener( "contextmenu", "onContextMenu" );
	}

	removeContextMenuListener() {
		return this.removeListener( "contextmenu" );
	}

	onContextMenu( evt, parameters = void 0 ) {
		DomEventHelper.stopEvent(evt);
		const parentObject = this.parentObject;
		if ( !Validator.isObject( parentObject ) || !Validator.isFunction( parentObject.onContextMenu ) ) {
			return;
		}
		parentObject.onContextMenu( evt, parameters );
	}

}

console.debug( 'widgets/xtw/rtp/XtwRowTemplateItem.js loaded.' );
