import ConditionInterpreter from '../../../utils/condition/ConditionInterpreter';
import Validator from '../../../utils/Validator';
import Warner from '../../../utils/Warner';
import XtwRowTemplateCellContentContainer from './XtwRowTemplateCellContentContainer';
import XtwRowTemplateCellContentItem from './XtwRowTemplateCellContentItem';
import XtwRowTemplateItem from './XtwRowTemplateItem';
import XtwUtils from '../util/XtwUtils';
import DomEventHelper from '../../../utils/DomEventHelper';

const ITEM_SPECIFIC_TEXT_COLOR = "--rtp-item-specific-text-color";
const SEPARATOR = "·";
const DO_LOG = false;

export default class XtwRowTemplateRow extends XtwRowTemplateItem {

	/**
	 * @class
	 * @override
	 * the main purpose, aside from constructing/creating a new object of this
	 * prototype, it to ensure the constructed object (it's creation parameters)
	 * has (have) an itemId
	 */
	constructor( parentObject, parameters ) {
		if ( !Validator.isObject( parameters ) ) parameters = {};
		const itemId = Validator.isNumber( parameters.itemId ) ?
			String( parameters.itemId ) :
			!Validator.isString( parameters.itemId ) &&
			Validator.is( parameters.item, "MDataRow" ) ?
			String( parameters.item.xid ) :
			Validator.generateRandomString( "XtwRowTemplateRow-" );
		Object.defineProperty( parameters, "itemId", {
			value: itemId,
			writable: false
		} );
		super( parentObject, parameters );
	}

	create() {
		super.create();
		this._defineTrueGetterProperty( "isRow" );
		this.reset();
	}

	reset() {
		this.removeChildren();
		this.children = Validator.isFunction( pisasales.creObjReg ) ?
			pisasales.creObjReg() : void 0;
		const idManager = this.idManager;
		this.unregisteredRelevantOperands = Validator.isObject( idManager ) ?
			new Set( idManager.relevantOperands ) : new Set();
		this.waitList = new Set();
		this.cellIdToContent = new Map();
		this.descriptionToContent = new Map();
		this.isNewRowTemplate ?
			this.createNewRtpCells() :
			this.createOldRtpCells();
	}

	get allOperandsRegistered() {
		return this.unregisteredRelevantOperands.size <= 0;
	}

	get cellIdToDescription() {
		return this.idManager.cellIdToDescriptionName;
	}

	get descriptionToCellId() {
		return this.idManager.descriptionNameToCellId;
	}

	render() {
		super.render();
		this.setGridTemplateColumns();
		this.addClickListener();
		this.addContextMenuListener();
	}

	removeOwnElement() {
		this.removeClickListener();
		this.removeContextMenuListener();
		super.removeOwnElement();
	}

	/**
	 * gets/finds and returns the XRowItem that hosts this object (as direct
	 * parent);
	 * @return {XRowItem} parentObject the x row item parent object, if present
	 */
	get parentXRowItem() {
		const parentObject = this.parentObject;
		return Validator.is( parentObject, "XRowItem" ) ? parentObject : void 0;
	}

	/**
	 * @override
	 * gets/finds and returns the table body, XtwBody, that hosts this object
	 * (not as a direct parent);
	 * @see XtwRtpItm~bodyParentObject provides original version of this getter
	 * @return {XtwBody} xtwBody the table body, if present
	 */
	get bodyParentObject() {
		const parentXRowItem = this.parentXRowItem;
		if ( !Validator.isObject( parentXRowItem ) ) {
			return void 0;
		}
		const xtwBody = parentXRowItem.tblBody;
		return Validator.is( xtwBody, "XtwBody" ) ? xtwBody : void 0;
	}

	/**
	 * gets/finds and returns the model data row (MDataRow) that hosts this
	 * object (not as a direct parent);
	 * @see XtwModel.js~MDataRow
	 * @return {MDataRow} mDataRow the model data row, if present
	 */
	get modelDataRow() {
		const parentXRowItem = this.parentXRowItem;
		if ( !Validator.isObject( parentXRowItem ) ) {
			return void 0;
		}
		const mDataRow = parentXRowItem.item;
		return Validator.is( mDataRow, "MDataRow" ) ? mDataRow : void 0;
	}

	/**
	 * gets the status of the "_isSelected" property of the model data row
	 * (MDataRow) corresponding to this item, only if the model data row
	 * (MDataRow that is hosting this item) is valid
	 * @see XtwModel.js~MDataRow
	 * @see XtwBody.js~XRowItem
	 * @return {Boolean} true if item selected, false if the model data row
	 * (MDataRow) is invalid or if the item is not selected
	 */
	get isSelected() {
		const parentXRowItem = this.parentXRowItem;
		if ( !Validator.isObject( parentXRowItem ) ||
			!( "isSelected" in parentXRowItem ) ) {
			return false;
		}
		return parentXRowItem.isSelected;
	}

	/**
	 * sets the status of the "_isSelected" property on the model data row
	 * (MDataRow) corresponding to this item, only if the model data row
	 * (MDataRow that is hosting this item) is valid and if the updating of the
	 * "_isSelected" state on the corresponding model data row (MDataRow) is not
	 * frozen/blocked
	 * @see XtwModel.js~MDataRow
	 * @see XtwBody.js~XRowItem
	 */
	set isSelected( newValue ) {
		const parentXRowItem = this.parentXRowItem;
		if ( !Validator.isObject( parentXRowItem ) ||
			!( "isSelected" in parentXRowItem ) ) {
			return;
		}
		parentXRowItem.isSelected = newValue;
	}

	select() {
		this.isSelected = true;
	}

	deselect() {
		this.isSelected = false;
	}

	/**
	 * selects this row through the selection manager (SelectionManager)
	 * that is attached to this item's "parent object" row (XRowItem), so that
	 * the information about current focus and selection is in sync with the
	 * states of this (and other) item(s); selecting the row through the
	 * selection manager automatically focuses the row;
	 * @param {Boolean} notify tells the selection manager whether or not to
	 * notify the web server about the selection change
	 * @param {Boolean} controlPressed whether or not the selection manager
	 * should act as if the control key is pressed
	 * @param {Boolean} shiftPressed whether or not the selection manager should
	 * act as if the shift key is pressed
	 * @return {Boolean} the success of the operation; true if everything went
	 * as planned, false otherwise
	 * @see XtwBody.js~XRowItem
	 * @see XtwRtpItm.js~SelectionManager
	 */
	syncSelect( notify = false, controlPressed = false, shiftPressed = false ) {
		const parentXRowItem = this.parentXRowItem;
		if ( !Validator.isObject( parentXRowItem ) ||
			!Validator.isFunction( parentXRowItem.syncSelect ) ) {
			return false;
		}
		return parentXRowItem.syncSelect( {
			notify: !!notify,
			controlPressed: !!controlPressed,
			shiftPressed: !!shiftPressed
		} );
	}

	/**
	 * deselects this row through the selection manager (SelectionManager)
	 * that is attached to this item's "parent object" row (XRowItem), so that
	 * the information about current focus and selection is in sync with the
	 * states of this (and other) item(s); deselecting the row through the
	 * selection manager automatically focuses the row;
	 * @param {Boolean} notify tells the selection manager whether or not to
	 * notify the web server about the selection change
	 * @param {Boolean} controlPressed whether or not the selection manager
	 * should act as if the control key is pressed
	 * @param {Boolean} shiftPressed whether or not the selection manager should
	 * act as if the shift key is pressed
	 * @return {Boolean} the success of the operation; true if everything went
	 * as planned, false otherwise
	 * @see XtwBody.js~XRowItem
	 * @see XtwRtpItm.js~SelectionManager
	 */
	syncDeselect( notify = false, controlPressed = false, shiftPressed = false ) {
		const parentXRowItem = this.parentXRowItem;
		if ( !Validator.isObject( parentXRowItem ) ||
			!Validator.isFunction( parentXRowItem.syncDeselect ) ) {
			return false;
		}
		return parentXRowItem.syncDeselect( {
			notify: !!notify,
			controlPressed: !!controlPressed,
			shiftPressed: !!shiftPressed
		} );
	}

	/**
	 * gets the status of the "_isFocused" property of the model data row
	 * (MDataRow) corresponding to this item, only if the model data row
	 * (MDataRow that is hosting this item) is valid
	 * @see XtwModel.js~MDataRow
	 * @return {Boolean} true if item focused, false if the model data row
	 * (MDataRow) is invalid or if the item is not focused
	 */
	get isFocused() {
		const parentXRowItem = this.parentXRowItem;
		if ( !Validator.isObject( parentXRowItem ) ||
			!( "isFocused" in parentXRowItem ) ) {
			return false;
		}
		return parentXRowItem.isFocused;
	}

	/**
	 * sets the status of the "_isFocused" property on the model data row
	 * (MDataRow) corresponding to this item, only if the model data row
	 * (MDataRow that is hosting this item) is valid and if the updating of the
	 * "_isFocused" state on the corresponding model data row (MDataRow) is not
	 * frozen/blocked
	 * @see XtwModel.js~MDataRow
	 */
	set isFocused( newValue ) {
		const parentXRowItem = this.parentXRowItem;
		if ( !Validator.isObject( parentXRowItem ) ||
			!( "isFocused" in parentXRowItem ) ) {
			return;
		}
		parentXRowItem.isFocused = newValue;
	}

	focus() {
		this.isFocused = true;
	}

	unfocus() {
		this.isFocused = false;
	}

	/**
	 * focuses this row through the selection manager (SelectionManager)
	 * that is attached to this item's "parent object" row (XRowItem), so that
	 * the information about current focus is in sync with the states of this
	 * (and other) item(s)
	 * @param {Boolean} notify tells the selection manager whether or not to
	 * notify the web server about the focus change
	 * @return {Boolean} the success of the operation; true if everything went
	 * as planned, false otherwise
	 * @see XtwBody.js~XRowItem
	 * @see XtwRtpItm.js~SelectionManager
	 */
	syncFocus( notify = false ) {
		const parentXRowItem = this.parentXRowItem;
		if ( !Validator.isObject( parentXRowItem ) ||
			!Validator.isFunction( parentXRowItem.syncFocus ) ) {
			return false;
		}
		return parentXRowItem.syncFocus( !!notify );
	}

	/**
	 * unfocuses this row through the selection manager (SelectionManager)
	 * that is attached to this item's "parent object" row (XRowItem), so that
	 * the information about current focus is in sync with the states of this
	 * (and other) item(s)
	 * @param {Boolean} notify tells the selection manager whether or not to
	 * notify the web server about the focus change
	 * @return {Boolean} the success of the operation; true if everything went
	 * as planned, false otherwise
	 * @see XtwBody.js~XRowItem
	 * @see XtwRtpItm.js~SelectionManager
	 */
	syncUnfocus( notify = false ) {
		const parentXRowItem = this.parentXRowItem;
		if ( !Validator.isObject( parentXRowItem ) ||
			!Validator.isFunction( parentXRowItem.syncUnfocus ) ) {
			return false;
		}
		return parentXRowItem.syncUnfocus( !!notify );
	}

	get rowHeight() {
		const idManager = this.idManager;
		if ( !Validator.isObject( idManager ) || !( "rowHeight" in idManager ) ) {
			return void 0;
		}
		return Validator.isPositiveNumber( idManager.rowHeight, false ) ?
			idManager.rowHeight : void 0;
	}

	setGridTemplateColumns() {
		if ( !this.isRendered ) return;
		const idManager = this.idManager;
		if ( !Validator.isObject( idManager ) ||
			!Validator.isString( idManager.gridTemplateColumns ) ) return;
		this.element.style.gridTemplateColumns = idManager.gridTemplateColumns;
	}

	createOldRtpCells() {
		const rcells = this.rcells;
		if ( !Validator.isMap( rcells ) ) return false;
		rcells.forEach( ( rowInternalGroup, internalGroupNameId ) => {
			if ( !Validator.isMap( rowInternalGroup ) ) return;
			rowInternalGroup.forEach( ( cellGroup, groupId ) => {
				this.createRowChildUnit( groupId, cellGroup );
			} );
		} );
	}

	createRowChildUnit( itemId, childMap ) {
		if ( !Validator.isString( itemId ) || !Validator.isMap( childMap ) )
			return false;
		let rowChildUnit = new XtwRowTemplateItem( this,
			Object.assign( {}, { itemId: itemId, childMap: childMap } ) );
		rowChildUnit.predefinedGetterElement = "newVerticalGrid";
		childMap.forEach( ( cell, cellId ) => {
			this.createCellContentContainer( cellId, cell, rowChildUnit );
		} );
		this.addChild( rowChildUnit );
		return true;
	}

	createCellContentContainer( itemId, parameters, parentObject ) {
		if ( !Validator.isString( itemId ) || !Validator.isObject( parameters ) ||
			!Validator.isObject( parentObject ) ) return false;
		const cell = new XtwRowTemplateCellContentContainer( parentObject,
			Object.assign( parameters, { itemId: itemId } ) );
		parentObject.addChild( cell );
		return true;
	}

	createNewRtpCells() {
		const rcells = this.rcells;
		if ( !Validator.isMap( rcells ) ) return false;
		rcells.forEach( ( rowInternalGroup, internalGroupNameId ) => {
			if ( [ "invisible-lines" ].indexOf( internalGroupNameId ) >= 0 ) return;
			this.createLinesContainerCell( internalGroupNameId, rowInternalGroup );
		} );
		return true;
	}

	createLinesContainerCell( itemId, childMap ) {
		if ( !Validator.isString( itemId ) || !Validator.isMap( childMap ) ) {
			return false;
		}
		let rowInternalGroup = new XtwRowTemplateItem( this, Object.assign( {}, {
			itemId: itemId,
			childMap: childMap
		} ) );
		let predefinedElement = rowInternalGroup.newVerticalGrid;
		if ( Validator.is( predefinedElement.dataset, "DOMStringMap" ) ) {
			predefinedElement.dataset.lines = [ ...childMap.keys() ].join( ", " );
		}
		// this._setPredefinedElementRowHeight( predefinedElement );
		rowInternalGroup.predefinedElement = predefinedElement;
		childMap.forEach( ( cellGroup, groupId ) => {
			this.createLine( groupId, cellGroup, rowInternalGroup );
		} );
		this.addChild( rowInternalGroup );
		return true;
	}

	_setPredefinedElementRowHeight( element ) {
		if ( !( element instanceof HTMLElement ) ) {
			return false;
		}
		let rowHeight = this.rowHeight;
		if ( !Validator.isPositiveNumber( rowHeight, false ) ) {
			const rowTemplateDefinition = this.rowTemplateDefinition;
			if ( !Validator.isObject( rowTemplateDefinition ) ||
				!Validator.isPositiveNumber( rowTemplateDefinition.height, false ) ) {
				return false;
			}
			rowHeight = rowTemplateDefinition.height;
		}
		const cssHeight = `${ rowHeight }px`;
		element.style.height = cssHeight;
		element.style.maxHeight = cssHeight;
		return true;
	}

	createLine( itemId, childMap, parentObject ) {
		if ( !Validator.isString( itemId ) || !Validator.isMap( childMap ) ||
			!Validator.isObject( parentObject ) )
			return false;
		const horizontalContentCellGroup = new XtwRowTemplateItem( parentObject,
			Object.assign( {}, { itemId: itemId, childMap: childMap } ) );
		const predefinedElement = horizontalContentCellGroup.newGridItem;
		const cellDescriptionNames = [];
		let alignment = void 0;
		let earliestPosition = Number.POSITIVE_INFINITY;
		childMap.forEach( ( cell, cellId ) => {
			if ( Validator.isString( cell.descriptionName ) ) {
				cellDescriptionNames.push( cell.descriptionName );
			}
			this.createContentCell( cellId, cell, horizontalContentCellGroup );
			if ( !Validator.isObjectPath( cell, "cell.rtpCol" ) ||
				!Validator.isString( cell.rtpCol.rtpDscJust ) ) return;
			const cellPosition = Number( cell.position );
			if ( !Validator.isValidNumber( cellPosition ) ||
				cellPosition > earliestPosition ) return;
			alignment = cell.rtpCol.rtpDscJust;
			earliestPosition = cellPosition;
		} );
		if ( cellDescriptionNames.length > 0 &&
			Validator.is( predefinedElement.dataset, "DOMStringMap" ) ) {
			predefinedElement.dataset.rcells = cellDescriptionNames.join( ", " );
		}
		const horizontalAlignment =
			this._getHoriznotalAlignmentInsideLine( alignment );
		if ( Validator.isString( horizontalAlignment ) ) {
			predefinedElement.style.justifyContent = horizontalAlignment;
		}
		const flexGrow = horizontalAlignment === "stretch" ? 1 : 0;
		childMap.forEach( ( cell, cellId ) => {
			cell.flexGrow = flexGrow;
		} );
		horizontalContentCellGroup.predefinedElement = predefinedElement;
		parentObject.addChild( horizontalContentCellGroup );
		return true;
	}

	_getHoriznotalAlignmentInsideLine( alignment ) {
		if ( !Validator.isString( alignment ) || alignment.length < 2 ) {
			return void 0;
		}
		const horizontalAlignment = alignment.charAt( 1 );
		return horizontalAlignment == "L" ? "flex-start" :
			horizontalAlignment == "R" ? "flex-end" :
			horizontalAlignment == "H" ? "center" :
			horizontalAlignment == "S" ? "space-between" :
			horizontalAlignment == "A" ? "space-around" :
			horizontalAlignment == "E" ? "space-evenly" :
			horizontalAlignment == "F" ? "stretch" : void 0;
	}

	createContentCell( itemId, parameters, parentObject ) {
		if ( !Validator.isString( itemId ) || !Validator.isObject( parameters ) ||
			!Validator.isObject( parentObject ) || !parameters.visible ) {
			return false;
		}
		const cell = new XtwRowTemplateCellContentItem( parentObject,
			Object.assign( parameters, { itemId: itemId } ) );
		parentObject.addChild( cell );
		return true;
	}

	updateCellContent( args, newRowTemplate = false ) {
		if ( !Validator.isObjectPath( args, "args.ctt" ) ) {
			return;
		}
		this._registerContent( args );
		if ( this.allOperandsRegistered ) {
			this._processWaitList();
		}
		const cell = newRowTemplate ? this._getNewCell( String( args.idc ) ) : this._getOldCell( String( args.idc ) );
		if ( !Validator.isObject( cell ) ) {
			return;
		}
		const interpretation = cell.condition;
		if ( Validator.isObjectPath( interpretation, "interpretation.condition" ) ) {
			return this.allOperandsRegistered ?
				this._setContentBasedOnCondition( cell, args ) :
				this._addToWaitList( cell, args )
		}
		if ( newRowTemplate && cell.renderBlocked ) {
			cell.renderBlocked = !Validator.isString( args.ctt.text );
		}
		cell.setContent( args.ctt.text, args.ctt.prop );
		this.setSeparatorsInsideLine( cell.parentObject );
	}

	_getOldCell( cellId ) {
		if ( !Validator.isString( cellId ) ) {
			return void 0;
		}
		const idManager = this.idManager;
		if ( !Validator.isObject( idManager ) || !Validator.isMap( idManager.cellIdToGroupId ) ) {
			return void 0;
		} 
		const groupId = idManager.cellIdToGroupId.get( cellId );
		const rowChildUnit = this.getChild( groupId );
		if ( !Validator.isObject( rowChildUnit ) ) {
			return void 0;
		}
		const cell = rowChildUnit.getChild( cellId );
		return Validator.isObject( cell ) ? cell : void 0;
	}

	_getNewCell( cellId ) {
		if ( !Validator.isString( cellId ) ) {
			return void 0;
		}
		const idManager = this.idManager;
		if ( !Validator.isObject( idManager ) ||
			!Validator.isMap( idManager.cellIdToInternalGroupNameId ) ||
			!Validator.isMap( idManager.cellIdToGroupId ) ) {
				return void 0;
			}
		const internalGroupNameId = idManager.cellIdToInternalGroupNameId.get( cellId );
		const rowInternalGroup = this.getChild( internalGroupNameId );
		if ( !Validator.isObject( rowInternalGroup ) ) {
			return void 0;
		}
		const groupId = idManager.cellIdToGroupId.get( cellId );
		const horizontalContentCellGroup = rowInternalGroup.getChild( groupId );
		if ( !Validator.isObject( horizontalContentCellGroup ) ) {
			return void 0;
		}
		const cell = horizontalContentCellGroup.getChild( cellId );
		return Validator.isObject( cell ) ? cell : void 0;
	}

	addToWaitList( cell, args ) {
		if ( !Validator.isObject( cell ) ||
			!Validator.isObjectPath( args, "args.ctt" ) ) return;
		const interpretation = cell.condition;
		if ( !Validator.isObjectPath( interpretation,
				"interpretation.condition" ) ) return;
		this._addToWaitList( cell, args );
	}

	_addToWaitList( cell, args ) {
		this.waitList.add( { cell: cell, args: args } );
	}

	setContentBasedOnCondition( cell, args ) {
		if ( !Validator.isObject( cell ) ||
			!Validator.isObjectPath( args, "args.ctt" ) ) return;
		const interpretation = cell.condition;
		if ( !Validator.isObjectPath( interpretation,
				"interpretation.condition" ) ) return;
		this._setContentBasedOnCondition( cell, args );
	}

	_setContentBasedOnCondition( cell, args ) {
		const interpretation = ConditionInterpreter.cloneBase( cell.condition );
		if ( !Validator.isObject( interpretation ) ) {
			Warner.traceIf( true, `Could not clone the cell condition.` );
			cell.setContent( args.ctt.text || '', args.ctt.prop );
			this.setSeparatorsInsideLine( cell.parentObject );
			return;
		}
		const mainCondition = interpretation.condition;
		mainCondition.modifyExpressionOperands( ( operand, operandIndex ) => {
			const content = this.descriptionToContent.get( operand );
			// TODO the whole "operandIndex === 0" rule is based on the fact that
			// most conditions are written in the form "TAG_NAME === VALUE" and not
			// in the form "VALUE === TAG_NAME", because VALUE is not supposed to
			// be registered anywhere, whilst TAG_NAME should be registered; the
			// index 0 (zero) is there because we do not want to be notified about
			// values and are hoping/counting on the fact that most conditions are
			// written as "TAG_NAME === VALUE" and not viceversa;
			if ( !Validator.isObject( content ) && operandIndex === 0 ) {
				const isOperandPresent = this.idManager.descriptionNames.indexOf( operand ) >= 0;
				const log = DO_LOG ? DO_LOG : !isOperandPresent;
				Warner.traceIf( log, `A problem occured when trying to set the` +
					` content of the cell <${ cell._creationParameters.parameters.descriptionName }>` +
					` based on the condition < ${ cell.condition.condition.stringify() } >:` +
					` the operand <${ operand }> is not present in the` +
					` "descriptionToContent" map of the row. The same operand` +
					` <${ operand }> is ${ ( isOperandPresent ? "" : "also not " ) }` +
					`present in the "descriptionNames" map of the id manager. If this` +
					` operand is not supposed to be a tag name, but rather its value,` +
					` ignore this message.` );
			}
			const text = Validator.isObject( content ) &&
				Validator.isString( content.text ) ? content.text : void 0;
			return Validator.isString( text ) ? text : operand;
		} );
		mainCondition.evaluate();
		// check content, but we must render empty strings as well
		const setContentAndShowCell = !Validator.isObject( args.ctt ) ? false :
			( mainCondition.result && interpretation.execution.ifTrue != "-" ) ||
			( !mainCondition.result && interpretation.execution.ifFalse != "-" );
		cell.renderBlocked = !setContentAndShowCell;
		if ( !setContentAndShowCell ) {
			return;
		}
		cell.setContent( args.ctt.text || '', args.ctt.prop );
		this.setSeparatorsInsideLine( cell.parentObject );
	}

	_processWaitList() {
		for ( let waitListEntry of this.waitList ) {
			this._setContentBasedOnCondition( waitListEntry.cell, waitListEntry.args );
			this.waitList.delete( waitListEntry );
		}
	}

	getLinesGroup( itemId ) {
		if ( !Validator.isString( itemId ) ||
			!Validator.is( this.children, "ObjReg" ) ) {
			return void 0;
		}
		const linesGroup = this.children.getObj( itemId );
		return Validator.is( linesGroup, "XtwRowTemplateItem" ) ?
			linesGroup : void 0;
	}

	getGroup( contentLineId ) {
		if ( Validator.isValidNumber( contentLineId ) ) {
			contentLineId = String( contentLineId );
		}
		if ( !Validator.isString( contentLineId ) ) {
			return void 0;
		}
		const idManager = this.idManager;
		if ( !Validator.isObject( idManager ) ) {
			return void 0;
		}
		const linesGroupId = this.idManager.groupIdToInternalGroupNameId.get( contentLineId );
		const linesGroup = this.getLinesGroup( linesGroupId );
		if ( !Validator.isObject( linesGroup ) || !Validator.is( linesGroup.children, "ObjReg" ) ) {
			return void 0;
		}
		const groupOrContentLine = linesGroup.children.getObj( contentLineId );
		return Validator.is( groupOrContentLine, "XtwRowTemplateItem" ) ? groupOrContentLine : void 0;
	}

	getContentCell( contentCellOrColumnId ) {
		if ( Validator.isValidNumber( contentCellOrColumnId ) ) {
			contentCellOrColumnId = String( contentCellOrColumnId );
		}
		if ( !Validator.isString( contentCellOrColumnId ) ) {
			return void 0;
		}
		const idManager = this.idManager;
		if ( !Validator.isObject( idManager ) ) {
			return void 0;
		}
		const contentLineOrGroupId = this.idManager.cellIdToGroupId.get( contentCellOrColumnId );
		const contentLineOrGroup = this.getGroup( contentLineOrGroupId );
		if ( !Validator.isObject( contentLineOrGroup ) ||
			!Validator.is( contentLineOrGroup.children, "ObjReg" ) ) {
			return void 0;
		}
		const contentCell = contentLineOrGroup.children.getObj( contentCellOrColumnId );
		return Validator.is( contentCell, "XtwRowTemplateCellContentItem" ) ? contentCell : void 0;
	}

	getContentCellFromDomElement( domElement ) {
		if ( !( domElement instanceof HTMLElement ) ||
			!( domElement.dataset instanceof DOMStringMap ) ) {
			return void 0;
		}
		const contentCell = this.getContentCell( domElement.dataset.id );
		return Validator.isObject( contentCell ) &&
			contentCell.element === domElement ? contentCell : void 0;
	}

	getContentFromDomElement( domElement ) {
		if ( !Validator.isMap( this.cellIdToContent ) ||
			!( domElement instanceof HTMLElement ) ||
			!( domElement.dataset instanceof DOMStringMap ) ) {
			return void 0;
		}
		const content = this.cellIdToContent.get( String( domElement.dataset.id ) );
		return Validator.isObject( content ) ? content : void 0;
	}

	setSeparatorsInsideLine( line ) {
		if ( !line.isRendered ) return false;
		const lineElement = line.element;
		const visibleNonSeparatorChildren = [ ...lineElement.children ].filter( child =>
			child instanceof HTMLElement &&
			!child.classList.contains( "rtp-separator" ) &&
			!child.classList.contains( "rtp-hidden" ) &&
			child.innerHTML.length > 0 );
		this.removeAllSeparators( lineElement );
		// at this point we should only have non-separator elements
		if ( visibleNonSeparatorChildren.length <= 1 ) {
			// there is only 1 element, no need for separators
			return true;
		}
		const cellsToInsertSeparatorsBefore =
			this._getCellsThatNeedSeparatorsBefore( visibleNonSeparatorChildren );
		return this._insertSeparatorsBeforeCells(
			lineElement, cellsToInsertSeparatorsBefore );
	}

	_insertSeparatorsBeforeCells( lineElement, items ) {
		if ( !( lineElement instanceof HTMLElement ) ||
			!Validator.isIterable( items ) ) {
			return false;
		}
		let atLeastOneSeparatorSet = false;
		for ( let item of items ) {
			if ( !Validator.isObject( item ) ||
				!( item.cell instanceof HTMLElement ) ) {
				continue;
			}
			const newSeparator = this.newSeparator;
			const separatorFontColor = this.getSeparatorFontColor( item );
			if ( Validator.isArray( separatorFontColor ) ) {
				const rgbaColor = XtwUtils.colorArrayToRgba( separatorFontColor );
				newSeparator.style.setProperty( ITEM_SPECIFIC_TEXT_COLOR, rgbaColor );
			}
			lineElement.insertBefore( newSeparator, item.cell );
			atLeastOneSeparatorSet = true;
		}
		return atLeastOneSeparatorSet;
	}

	getSeparatorFontColor( item ) {
		if ( !Validator.isObject( item ) ) {
			return void 0;
		}
		return this.getCommonContentFontColor( item.cell, item.cellBefore );
	}

	getCommonContentFontColor( firstElement, secondElement ) {
		const firstContent = this.getContentFromDomElement( firstElement );
		if ( !Validator.isObjectPath( firstContent, "firstContent.formatting" ) ||
			!Validator.isArray( firstContent.formatting.txc ) ) {
			return void 0;
		}
		const secondContent = this.getContentFromDomElement( secondElement );
		if ( !Validator.isObjectPath( secondContent, "secondContent.formatting" ) ||
			!Validator.isArray( secondContent.formatting.txc ) ) {
			return void 0;
		}
		return Validator.arraysEqual( firstContent.formatting.txc, secondContent.formatting.txc ) ?
			firstContent.formatting.txc : void 0;
	}

	_getCellsThatNeedSeparatorsBefore( cells ) {
		if ( !Validator.isIterable( cells ) ) {
			return void 0;
		}
		const allCells = [ ...cells ].filter( cell => cell instanceof HTMLElement );
		const cellsToInsertSeparatorsBefore = [];
		for ( let cellIndex = 1; cellIndex < allCells.length; cellIndex++ ) {
			const cell = allCells[ cellIndex ];
			const cellIsBlob = !Validator.is( cell.dataset, "DOMStringMap" ) ? false :
				!( "isBlob" in cell.dataset ) ?
				false : [ true, "true" ].indexOf( cell.dataset.isBlob ) >= 0;
			const previousCell = allCells[ cellIndex - 1 ]; // there always is a previous cell
			const previousCellIsBlob = !Validator.is( previousCell.dataset, "DOMStringMap" ) ?
				false : !( "isBlob" in previousCell.dataset ) ?
				false : [ true, "true" ].indexOf( previousCell.dataset.isBlob ) >= 0;
			if ( cellIsBlob && previousCellIsBlob ) { // this is the new condition
				continue;
			}
			const item = { cell: cell, cellBefore: cell };
			cellsToInsertSeparatorsBefore.push( item );
		}
		return cellsToInsertSeparatorsBefore;
	}

	/**
	 * @deprecated
	 */
	_setSeparatorsInsideLine( line ) {
		if ( !line.isRendered ) return false;
		const lineElement = line.element;
		const visibleNonSeparatorChildren = [ ...lineElement.children ].filter( child =>
			child instanceof HTMLElement &&
			!child.classList.contains( "rtp-separator" ) &&
			!child.classList.contains( "rtp-hidden" ) &&
			child.innerHTML.length > 0 );
		this.removeAllSeparators( lineElement );
		// at this point we should only have non-separator elements
		if ( visibleNonSeparatorChildren.length <= 1 ) {
			// there is only 1 element, no need for separators
			return true;
		}
		const cellsToInsertSeparatorsBefore = [];
		for ( let cellIndex = 0; cellIndex < visibleNonSeparatorChildren.length - 1; cellIndex++ ) {
			const cell = visibleNonSeparatorChildren[ cellIndex ];
			const cellIsBlob = !Validator.is( cell.dataset, "DOMStringMap" ) ? false :
				!( "isBlob" in cell.dataset ) ?
				false : [ true, "true" ].indexOf( cell.dataset.isBlob ) >= 0;
			if ( cellIsBlob ) continue;
			const nextCell = visibleNonSeparatorChildren[ cellIndex + 1 ]; // there always is a next cell
			const nextCellIsBlob = !Validator.is( nextCell.dataset, "DOMStringMap" ) ?
				false : !( "isBlob" in nextCell.dataset ) ?
				false : [ true, "true" ].indexOf( nextCell.dataset.isBlob ) >= 0;
			if ( nextCellIsBlob ) {
				cellIndex++;
				continue;
			}
			cellsToInsertSeparatorsBefore.push( nextCell );
		}
		for ( let cell of cellsToInsertSeparatorsBefore ) {
			const newSeparator = this.newSeparator;
			lineElement.insertBefore( newSeparator, cell );
		}
		return true;
	}

	getAllSeparators( lineElement ) {
		if ( !( lineElement instanceof HTMLElement ) ) {
			return [];
		}
		const separatorChildren = [ ...lineElement.children ].filter( child =>
			child instanceof HTMLElement &&
			child.classList.contains( "rtp-separator" ) );
		return separatorChildren;
	}

	removeAllSeparators( lineElement ) {
		if ( !( lineElement instanceof HTMLElement ) ) {
			return false;
		}
		const separatorChildren = this.getAllSeparators( lineElement );
		if ( !Validator.isIterable( separatorChildren ) ) {
			return false;
		}
		for ( let separatorElement of separatorChildren ) {
			separatorElement.innerHTML = "";
			lineElement.removeChild( separatorElement );
		}
		return true;
	}

	get newSeparator() {
		const separator = window.document.createElement( "div" );
		separator.classList.add( "rtp-separator" );
		separator.innerHTML = SEPARATOR;
		return separator;
	}

	_registerContent( args ) {
		if ( !Validator.isObjectPath( args, "args.ctt" ) ) return;
		const cellId = String( args.idc );
		// const content = String( args.ctt.text );
		const content = {
			text: String( args.ctt.text ),
			formatting: args.ctt.prop
		};
		this.cellIdToContent.set( cellId, content );
		const description = this.cellIdToDescription.get( cellId );
		this.descriptionToContent.set( description, content );
		this.unregisteredRelevantOperands.delete( description );
	}

	onClick( evt, parameters = void 0 ) {
		if ( !Validator.isObject( parameters ) ) {
			parameters = {};
		}
		parameters.idr = Number( this.itemId );
		const eventComesFromChild = Validator.isString( parameters.fromItem ) && parameters.fromItem !== Validator.getClassName( this );
		if ( !eventComesFromChild ) {
			return;
		}
		parameters.fromItem = void 0;
		delete parameters.fromItem;
		this.onLinkClick( evt, parameters );
	}

	onLinkClick( evt, parameters = void 0 ) {
		if ( !Validator.isObject( parameters ) ) {
			parameters = {};
		}
		if ( evt instanceof MouseEvent ) {
			evt.preventDefault();
			evt.stopPropagation();
		}
		this._nfySrv( "linkClicked", parameters );
	}

	onContextMenu( evt, parameters = void 0 ) {
		if ( !this.isRendered ) {
			return;
		}
		DomEventHelper.stopEvent(evt);
		this.selectAndFocusRow( evt );
		if ( Validator.isObject( parameters ) ) {
			this.parentObject.nfyCtxMenu(parameters);
		} else {
			const args = XtwUtils.getCoordinateParameters( evt, this.element );
			args.idr = Number(this.itemId);
			this.parentObject.nfyCtxMenu(args);
		}
	}

	selectAndFocusRow( evt ) {
		const parentXRowItem = this.parentXRowItem;
		if ( !Validator.isObject( parentXRowItem ) ||
			!Validator.isFunction( parentXRowItem.selectAndFocusRow ) ) {
			return false;
		}
		return parentXRowItem.selectAndFocusRow( evt );
	}

}
